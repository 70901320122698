<template>
  <div :class="{ 'c-dark-theme': $store.state.darkMode, animated: false, fadeIn: true }"
       style="background-color: #000000">
    <!-- 탱크재고 조회 --------------------------------------------------------------------------------------------->

<!--    <BOverlay :show="loadingShow"-->
<!--              style="min-height: 600px"-->
<!--              rounded="lg">-->

    <div class="content-center"
         v-if="!this.$route.query.mode"
         style="background-color: #181924">
      <b-spinner style="width: 3rem; height: 3rem;"
                 label="Large Spinner"
                 v-if="loadingShow"
                 class="mt-5"
                 type="grow">
      </b-spinner>
    </div>


    <div v-if="tanks.length" class="min-vh-100">

      <BCard v-for="(tanks, arCode) in filteredAreas"
           v-show="tanks.length"
           :key="arCode"
           style="min-width: 500px"
           border-variant="info"
           class="mb-1"
    >

      <div slot="header">
        <!--        <BIconOctagonFill  class="mr-1"/>-->

        <BButton class="ml-1 font-weight-bolder"
                 variant="outline-warning"
                 size="sm"
                 @click="gotoMonitor(arCode)">
          <BIconBack/>
          {{ arCodeName[arCode] }} 모니터링
        </BButton>


        <div class="card-header-actions text-muted small">
          <BBadge> {{ arCodeName[arCode] }} </BBadge> {{ arCodes[arCode]?arCodes[arCode].description:'' }}
        </div>

      </div>

      <BRow>
        <BCol :md="3" v-for="tank in tanks" :key="tank.tid" style="min-width: 445px;max-width: 500px">


          <div v-if="tank.tankShape==='4'">
            <Tank2D :ref="tank.tid"
                       :width="300"
                       :height="250"
                       :tank="tank"
                       :bg-color="$store.state.darkMode? darkGray2:white"
            />
          </div>
          <div v-else>
            <Tank2D :ref="tank.tid"
                    :width="300"
                    :height="250"
                    :tank="tank"
                    :bg-color="$store.state.darkMode? darkGray2:white"
            >
            </Tank2D>
          </div>


        </BCol>
      </BRow>

    </BCard>
    </div>

<!--    </BOverlay>-->
  </div>
</template>

<script>

//-------------------------------------------------------------------------------------------------
import '@/common/HelperMixin';
import Tank2D from '../components/tank-model/Tank2D';
import {
  apiCall, modalSuccess, modalWarn, sleep, speech,
} from '@/common/utils';
import {TankUseNameMap} from '@/common/constants';

// import TankLorry from "@/views/components/tank-model/TankLorry.vue";
// import qs from 'querystring';
// import moment from "moment";
// import axios from "axios";


// const recvTypeMap = { manual: "매뉴얼", api: "API", socket: "소켓" };


//----------------------------------------------------------------------------------------------------
export default {
  name: "MonitorMain",
  props: {
    win: { type: String, default: null},
  },
  components: {
    // TankLorry,
    Tank2D
  },
  data () {
    return {
      TankUseNameMap,

      loadingShow: true,
      loadingKey: 0,
      tp: null,
      areas: null,
      tanks: [],
      areaShow: {},
      tid: '',
      tids: [],
      tankOpts : this.$store.state.tanks['opts'],
      tankKey: 0,
      darkGray: '#181924',
      darkGray2: '#24252F',
      white: '#FFFFFF',
      maps: this.$store.state.codeMaps,
      selectedTank: null,
      selectedMachineId: null,
      tank : null,
      tlgPacket: {},
      tankInstances : {},

      downloadModalShow: false,
      machineMap  : {},
      oilCodeMap  : {},
      arCodeName  : this.$store.state.area['map'],
      arCodeMaps  : this.$store.state.area['maps'],
      areaOpts  : this.$store.state.area['opts'],
      arCodes : this.$store.state.area['codes'],
      arCode: '',
      downloadReason: '',
      searchOpts: [
        {value:'tid',        text:'탱크아이디'},
        {value:'name',       text:'탱크명'},
        {value:'arCode',     text:'지역코드'},
      ],
      searchField: 'tid',
      searchWord : '',
      progCount: 0,
      totalCount: 0,
      rows: [],

      paramMap: {},
      numOfRecord: 10,
      updateKey: 0,

      modalTitle: '',
      tankFormShow: false,
      isPopupMode: false,

      socket: this.$store.state.socket,
      listenerNameMap: {},
      listenerNames: [],

    }

  },
  async created(){
    // console.log("^^^^^^^^^^^^^^^^^^ TankMonitor creating ^^^^^^^^^^^^^^^^");
    try{
      this.loadingShow = true;

      // {value: r.arCode, text: map[r.arCode], label: map[r.arCode] }
      // console.log( 'tank options--->', this.tankOpts );


      if(!this.$store.state.socket){
        console.error("[MONITOR-MAIN] ############ socket not exists...", this.$store.state.socket);
        console.warn("[MONITOR-MAIN] ############ waiting register handler...");
      }else{
        console.warn( "[MONITOR-MAIN] ############ socket object ----->", this.$store.state.socket);
        console.warn("[MONITOR-MAIN] ############ waiting register handler...");
      }


    }catch(err){
      console.error(err);
      alert(err.message);
      window.close();
    }
    // console.debug("^^^^^^^^^^^^^^^^^^ MonitorMain created ^^^^^^^^^^^^^^^^");
  },

  computed: {
    filteredAreas() {
      const userAreas = (JSON.parse(localStorage.getItem('user')) || [])?.areas || [];
      return Object.keys(this.areas)
        .filter(arCode => userAreas.includes(arCode))
        .reduce((obj, key) => {
          obj[key] = this.areas[key];
          return obj;
        }, {});
    }
  },

  async mounted() {
    try {
      // console.debug("^^^^^^^^^^^^^^^^^^ MonitorMain mounting ^^^^^^^^^^^^^^^^");



      await sleep(1000);
      this.arCodeName = this.$store.state.area['map'];
      this.arCodeMaps = this.$store.state.area['maps'];
      this.areaOpts = this.$store.state.area['opts'];
      this.arCodes = this.$store.state.area['codes'];
      this.loadingShow = false;

      await this.getTanks();

      await sleep(1000);

      await this.setSocketListener();

      for( const tid of this.tids ){
        let instance;
        if( Array.isArray(this.$refs[tid]) ) instance = this.$refs[tid][0];
        else instance = this.$refs[tid];
        this.tankInstances[tid] = instance;
      }
      console.log( 'tank instances ----> ', this.tankInstances );

    }catch(err){
      console.error('TankMonitor Mount error: ', err);
    }finally{
      console.debug("^^^^^^^^^^^^^^^^^^ MonitorMain mounted ^^^^^^^^^^^^^^^^");
    }

  },

  beforeDestroy(){
    // using "removeListener" here, but this should be whatever $socket provides
    // for removing listeners
    if(this.socket) {

      for( const lnm of this.listenerNames){
        this.socket.removeListener(lnm, this.atgDataHandler);
        console.warn("[MONITOR-MAIN] beforeDestroy ____________ remove Handler ____________", lnm );
      }

      this.socket.removeListener('disconnect' );
      this.socket.removeListener('connect' );
    }
  },

  methods: {

    gotoMonitor(arCode, tankId='') {

      let code = arCode? arCode: this.arCode;
      let tid = tankId? tankId: '';
      let mode = tid? 'tank':'area';

      let query = `mode=${mode}&arCode=${code}&tid=${tid}`;
      console.log( '%%%%%%%%%%%%%%%% gotoMonitor ------------- store.state.user ---->', this.$store.state.user );
      let width=930;
      let winName = code;
      if(tid) {
        width = 514;
        winName = tid;
      }

      let route = this.$router.resolve({ path: `/monitoring?${query}`});
      const features = `left=0,top=0,width=${width},height=545,status=no,titlebar=no,location=no,menubar=no,toolbar=no`
      const newWin = window.open(route.href, `monitor_${winName}`, features );
      newWin.storedUser = localStorage.user;
      console.log(  'stored user --->', newWin.storedUser );
    },

    async setSocketListener(){
      try{
        console.warn("[MONITOR-MAIN] ############ register atgDataHandler #########");
        this.socket = this.$store.state.socket;
        if(this.socket) {

          this.socket.removeListener('disconnect' );
          this.socket.removeListener('connect' );

          this.socket.on('disconnect', ()=>{
            // speech('ATG 서버와 통신이 유실 되었습니다. ,,화면을 종료하고 재접속 하시기 바랍니다.');
            speech('서버와 통신이 유실 되었습니다.');
            modalWarn(this.$bvModal, '서버와 통신이 유실 되었습니다.');
            // alert("[WARN] ATG 서버와 통신이 유실되었습니다. 모니터링을 종료합니다.")
            // window.close();
          });
          this.socket.on('connect', ()=>{
            speech('서버와 통신이 연결 되었습니다.');
            modalSuccess(this.$bvModal, '서버와 통신이 연결 되었습니다.');
          });

          for( const lnm of this.listenerNames){
            this.socket.removeListener(lnm, this.atgDataHandler);
            this.socket.on( lnm, this.atgDataHandler );
            console.warn("[MONITOR-MAIN] ____________ register ____", lnm )
          }


        }
        console.warn( "[MONITOR-MAIN] ############ socket object ------> is connecting? ", this.socket.connected );
      }catch(err){
        console.error(err);
      }

    },

    atgDataHandler(data){
      // console.info('atg-data emitted --- data --->', data.payload.dt );

      const {tid} = data;
      let instance = this.tankInstances[tid];
      if(instance) instance.setVolume(data);

      /*
      if( this.tids.indexOf(tid) > -1 ){
        let instance;
        if( Array.isArray(this.$refs[tid]) ) instance = this.$refs[tid][0];
        else instance = this.$refs[tid];

        if( instance ) {
          // console.info( 'tank instance ok --->', tp.tid );
          instance.setVolume(data);
        }
      }
      */


    },



    async getTanks(){
      try{

        this.areas = {};
        const userRole = (JSON.parse(localStorage.getItem('user')) || [])?.role;
        if(!this.arCode){
          this.areaOpts.map( r=>{
            let code = r.value
            if( !code ) code = 'BASE';
            this.areas[code] = [];
          });
        }else{
          this.areas[this.arCode] = [];
          this.areaShow[this.arCode] = true;
        }
        console.log('getTanks this.arCode', this.arCode)
        let query = `order=arCode&arCode=${this.arCode}&tid=${this.tid}`

        this.tank = null;
        const r = await apiCall('get', `/api/tank?${query}`);
        let dispCount = 0;

        if(r.code===200){
          r.result.map(t=>{
            if(t.display){
              dispCount++;
              let arCode = t.arCode;
              if(!arCode) arCode = 'BASE';
              this.areas[arCode].push( t );

              this.tanks.push( t );
              this.tids.push( t.tid );
              // console.log( arCode, t );
            }

            this.listenerNameMap[t.tankUse] = TankUseNameMap[t.tankUse];

          })

          // console.log( '-----areas --------->', this.areas );
          // console.log( this.tanks );
          // this.tanks = r.result;
          if((userRole === 'SA') || (userRole === 'SM')) {
          await this.toastInfo(`탱크 ${r.result.length}기 중 ${dispCount}기 표시`, 'info');
        }


/*
          setTimeout(()=>{
            Object.keys( this.areas ).map(key=>{
              console.log( '------------ area key --->', key)

              this.areas[key].map(t=>{
                console.log( '-----------tankInstances set ------->', t.tid );
                // this.tankInstances[ t.tid ] = this.$refs[ t.tid ][0];
                // console.log( '-----------tankInstances ------->', this.tankInstances[ t.tid ] );

              });
            });


          }, 500 * r.result.length );
      */

          // console.log( 'task-data-query-result--->', r.result  );
        }

        Object.keys(this.listenerNameMap).map(k=>{ this.listenerNames.push(this.listenerNameMap[k]) });
        console.warn( 'getTanks______listenerNames____________', this.listenerNames );

      }catch(err){
        // console.log( 'getTankList----------------->', err );
        this.toastError(err);
      }

    },

  }
}
</script>
